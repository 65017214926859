<template>
    <b-overlay :show="formShow" rounded="sm" no-fade>
        <b-card v-if="!formShow">
            <b-row>
                <table class="table table-sm table-dark">
                    <thead>
                        <tr>
                            <th scope="col" v-show="!eventId">New Event</th>
                            <th scope="col" v-show="eventId">
                                <b-button v-show="eventId" block class="btn-sm">{{ eventData.date }} </b-button>
                            </th>
                        </tr>
                    </thead>
                </table>
            </b-row>

            <validation-observer ref="eventRules">
                <b-form>
                    <b-row class="mt-2">
                        <!-- Name -->
                        <b-col v-show="!eventId" cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Date*" label-for="date">
                                <validation-provider name="Date" #default="{ errors }" rules="required">
                                    <flat-pickr id="date" input-id="date" v-model="eventData.date" :state="errors.length > 0 ? false : null" class="form-control" @input="dateChange()" :config="{ mode: 'multiple', locale: { firstDayOfWeek: 1 }, dateFormat: 'd/m/Y' }" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Location*" label-for="location">
                                <validation-provider name="Location" #default="{ errors }" rules="required">
                                    <b-form-input id="location" input-id="location" type="text" v-model="eventData.location" :state="errors.length > 0 ? false : null"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Event Group Reference" label-for="groupReference">
                                <!-- <validation-provider name="Client" #default="{ errors }" rules="required"> -->
                                <v-select v-model="eventData.groupReference" @option:created="createNewReference" :taggable="true" :options="groupRefs" input-id="groupReference" />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->

                                <!-- </validation-provider> -->
                            </b-form-group>
                        </b-col>

                        <!-- Field: Invoice No -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="invoiceNo">Invoice No</label>

                                <b-form-input id="invoiceNo" type="text" v-model="eventData.invoiceNo"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <!-- Field: Vendor Reference -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="vendorReference">PO Number</label>
                                <!-- <validation-provider name="PO Number" #default="{ errors }" rules="required"> -->
                                <b-form-input id="vendorReference" type="text" v-model="eventData.vendorReference"></b-form-input>
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                <!-- </validation-provider> -->
                            </b-form-group>
                        </b-col>

                        <!-- Field: Client -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Client" label-for="client">
                                <!-- <validation-provider name="Client" #default="{ errors }" rules="required"> -->
                                <v-select v-model="eventData.client_id" @input="clientInputMethod" :options="clientOptions" :reduce="(val) => val.id" label="name" :clearable="false" input-id="client" />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                <!-- </validation-provider> -->
                            </b-form-group>
                        </b-col>

                        <!-- Field: Vehicle -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Vehicle" label-for="vehicle">
                                <!-- <validation-provider name="vehicle" #default="{ errors }" rules="required"> -->
                                <v-select v-model="eventData.vehicle_id" :options="vehicleOptions" :reduce="(val) => val.id" label="name" :clearable="false" multiple input-id="vehicle" />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                <!-- </validation-provider> -->
                            </b-form-group>
                        </b-col>

                        <!-- Field: Location -->

                        <!-- Field: Employees Required -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="employeesRequired">Employees Required</label>
                                <!-- <validation-provider name="Employees Required" #default="{ errors }" rules="required"> -->
                                <!-- <b-form-input id="employeesRequired" type="number" v-model="eventData.employeesRequired" :state="errors.length > 0 ? false : null"></b-form-input> -->

                                <cleave id="employeesRequired" v-model="eventData.employeesRequired" class="form-control" :raw="false" :options="$func.getInputOptions().number" />

                                <!-- getInputOptions -->

                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                <small v-if="countVehicleCal < eventData.employeesRequired" class="text-warning">
                                    <p>Insufficient vehicle seats for employees required {{ countVehicleCal }} / {{ eventData.employeesRequired }}.</p>
                                </small>
                                <!-- </validation-provider> -->
                            </b-form-group>
                        </b-col>

                        <!-- Field: Event Currency -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Event Currency" label-for="role">
                                <v-select v-model="eventData.currency" @input="costUpdate" :options="currencies" :reduce="(val) => val.id" label="code" :clearable="false" input-id="role" />
                            </b-form-group>
                        </b-col>

                        <!-- Field: Congestion Charge -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Congestion Charge" label-for="congestionCharge">
                                <!-- <validation-provider name="Congestion Charge" #default="{ errors }" rules="required"> -->
                                <!-- <b-form-input id="congestionCharge" input-id="congestionCharge" type="number" @input="costUpdate" v-model="eventData.congestionCharge" :state="errors.length > 0 ? false : null"></b-form-input> -->
                                <cleave id="congestionCharge" v-model="eventData.congestionCharge" @input="costUpdate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />

                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                <!-- </validation-provider> -->
                            </b-form-group>
                        </b-col>

                        <!-- Field: Rate -->
                        <!-- <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Rate" label-for="rate">
                                <validation-provider name="Rate" #default="{ errors }" rules="required">
                                    <v-select @input="costUpdate" v-model="eventData.rateType" :state="errors.length > 0 ? false : null" :options="rateOptions" :reduce="(val) => val.id" label="name" :clearable="false" input-id="rate" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col> -->

                        <!-- Field: Notes -->
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="notes">Notes</label>

                                <b-form-input id="notes" type="text" v-model="eventData.notes"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-card>
                                <b-row>
                                    <!-- <b-col cols="12" sm="6" md="4" :xl="eventId ? '4' : '3'">
                                        <b-form-group>
                                            <label for="foreman_rate">Foreman Rate</label>
                                            <cleave id="foreman_rate" v-model="eventData.foreman_rate" @input="costUpdate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="6" md="4" :xl="eventId ? '4' : '3'" v-if="eventId == undefined">
                                        <b-form-group>
                                            <label for="foreman_w_rate">Foreman Weekend Rate</label>
                                            <cleave id="foreman_w_rate" v-model="eventData.foreman_w_rate" @input="costUpdate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="6" md="4" :xl="eventId ? '4' : '3'">
                                        <b-form-group>
                                            <label for="f_overtime_rate">Foreman Overtime Rate</label>
                                            <cleave id="f_overtime_rate" v-model="eventData.f_overtime_rate" @input="costUpdate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                        </b-form-group>
                                    </b-col> -->

                                    <!-- Field: Foreman lodging Rate -->
                                    <b-col cols="12" sm="6" md="4" :xl="eventId ? '4' : '3'">
                                        <b-form-group>
                                            <label for="f_lodging">Foreman Lodging Allowance</label>
                                            <cleave id="f_lodging" v-model="eventData.f_lodging" @input="costUpdate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                        </b-form-group>
                                    </b-col>

                                    <!-- <b-col cols="12" sm="6" md="4" :xl="eventId ? '4' : '3'">
                                        <b-form-group>
                                            <label for="staff_rate">Staff Rate</label>
                                            <cleave id="staff_rate" v-model="eventData.staff_rate" @input="costUpdate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="6" md="4" :xl="eventId ? '4' : '3'" v-if="eventId == undefined">
                                        <b-form-group>
                                            <label for="staff_w_rate">Staff Weekend Rate</label>
                                            <cleave id="staff_w_rate" v-model="eventData.staff_w_rate" @input="costUpdate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="6" md="4" :xl="eventId ? '4' : '3'">
                                        <b-form-group>
                                            <label for="overtime_rate">Staff Overtime Rate</label>
                                            <cleave id="overtime_rate" v-model="eventData.overtime_rate" @input="costUpdate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                        </b-form-group>
                                    </b-col> -->

                                    <b-col cols="12" sm="6" md="4" :xl="eventId ? '4' : '3'">
                                        <b-form-group>
                                            <label for="lodging">Staff Lodging Allowance</label>
                                            <cleave id="lodging" v-model="eventData.lodging" @input="costUpdate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>

                        <!-- Field: Hotel List -->
                        <b-col cols="12">
                            <b-card>
                                <b-row>
                                    <b-col cols="12" sm="6" md="4" xl="2">
                                        <b-form-group>
                                            <label for="hotel">Hotel Name</label>
                                            <b-form-input id="hotel" type="text" v-model="hotelSelect"></b-form-input>

                                            <!-- <v-select input-id="hotel" v-model="hotelSelect" :options="hotelOptions" :reduce="(val) => val" :taggable="true" label="title" @option:created="createNewHotelValue" @option:selected="selectedHotelValue" :clearable="false" /> -->
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="6" md="4" xl="2">
                                        <b-form-group>
                                            <label for="hotelPrice">Hotel Price</label>
                                            <!-- <b-form-input id="hotelPrice" type="number" v-model="hotelPrice"></b-form-input> -->
                                            <cleave id="hotelPrice" v-model="hotelPrice" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="6" md="4" xl="2">
                                        <b-form-group>
                                            <label for="numberOfStaff">Staff Count</label>
                                            <!-- <b-form-input id="numberOfStaff" type="number" v-model="numberOfStaff"></b-form-input> -->
                                            <cleave id="numberOfStaff" v-model="numberOfStaff" class="form-control" :raw="true" :options="$func.getInputOptions().number" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="1" md="1" xl="1">
                                        <b-form-group class="mt-2">
                                            <b-button @click="hotelAdd" :class="{ disabled: !(hotelSelect && hotelPrice && numberOfStaff) }" variant="primary">
                                                <feather-icon icon="PlusIcon" />
                                            </b-button>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="5" md="5" xl="5">
                                        <b-alert variant="secondary" show>
                                            <div class="alert-body">
                                                <span class="text-center h5">Hotel List</span>
                                            </div>
                                        </b-alert>
                                        <b-table bordered hover ref="refHotelListTable" class="position-relative" :items="hotelList" filter-debounce="250" responsive :small="true" head-variant="dark" :fields="tableHotelColumns" show-empty empty-text="No matching records found">
                                            <template #cell(id)="data"><span class="text-bold text-primary">#{{ data.value }}</span>
                                            </template>

                                            <template #cell(name)="data">
                                                {{ data.value }}
                                            </template>

                                            <template #cell(price)="data">
                                                {{ $func.formatPrice(data.value, eventData.currency) }}
                                            </template>

                                            <template #cell(action)="data">
                                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon btn-sm" @click="hotelDelete(data)">
                                                    <feather-icon icon="XIcon" />
                                                </b-button>
                                            </template>
                                        </b-table>

                                        <b-form-group>


                                            <div class="side-by-side-container">
                                            <label for="notBooked">Hotel Not Booked</label>
                                            <b-form-checkbox id="notBooked" v-model="notBooked"/>

                                            <label for="numberOfStaff">Staff Count</label>
                                            <cleave id="numberOfStaff" v-model="notBookedCount" class="form-control" :raw="true" :options="$func.getInputOptions().number" />

                                            <b-button @click="hotelAddNotBooked" :class="{ disabled: !(notBooked && notBookedCount) }" variant="primary">
                                                <feather-icon icon="PlusIcon" />
                                            </b-button>

                                            </div>

                                         

                                            <!-- <v-select input-id="hotel" v-model="hotelSelect" :options="hotelOptions" :reduce="(val) => val" :taggable="true" label="title" @option:created="createNewHotelValue" @option:selected="selectedHotelValue" :clearable="false" /> -->
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="6" md="4" xl="2">
                                      
                                    </b-col>

                                    <b-col cols="12" sm="6" md="4" xl="2">
                                        
                                    </b-col>

                                    <b-col cols="12" sm="6" md="4" xl="2">
                                        <b-form-group>
                                          
                                        </b-form-group>
                                    </b-col>

                                </b-row>

                            

                            </b-card>
                        </b-col>

                        <!-- Field: Team List -->
                        <b-col cols="12">
                            <b-card>
                                <!-- <b-alert show dismissible fade class="mb-0" variant="danger" v-show="!(eventData.date && eventData.rateType)">
                  <div class="alert-body mb-1">
                    <span>The staff list will be activated after the required fields above are completed.</span>
                  </div>
                </b-alert> -->

                                <b-row>
                                    <!-- Field: Client -->

                                    <b-col cols="12" sm="6" md="4" xl="3">
                                        <b-form-group label="Team Select" label-for="teamSelect">
                                            <v-select v-model="teamSelect" :options="teamOptions" :class="{ disabled: !(eventData.date && eventData.rateType) }" @input="teamSelectDropdown" :reduce="(val) => val" label="name" :clearable="false" />
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="6" md="4" xl="3">
                                        <b-form-group label="Staff Select" label-for="staffSelect">
                                            <v-select v-model="staffSelect" :options="filteredStaffList" :class="{ disabled: !(eventData.date && eventData.rateType) }" @input="staffSelectDropdown" :reduce="(val) => val" label="label" :clearable="false">

                                                <template #option="{ availability_date, role, label }">


                                                    <!-- orhan -->

                                                    <span :class="selectDropDownCheck(eventData.date, availability_date) ? 'bg-danger p-25 text-white' : ''" v-if="role == 'Foreman'">🤵🏻 {{ label }}</span>
                                                    <span :class="selectDropDownCheck(eventData.date, availability_date) ? 'bg-danger p-25 text-white' : ''" v-else>🧍🏻 {{ label }}</span>

                                                </template>


                                            </v-select>

                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" sm="3" md="3" xl="3">
                                        <b-form-group class="mt-2">
                                            <b-button @click="staffAdd" v-ripple.400="'rgba(255, 255, 255, 0.15)'" :class="{ disabled: !(eventData.date && eventData.rateType) }" v-show="!stafCheckk" variant="primary">
                                                <feather-icon icon="PlusIcon" />
                                            </b-button>

                                            <b-alert variant="warning" show v-show="stafCheckk">
                                                <div class="alert-body">
                                                    <span>You can't add already existing in the list.</span>
                                                </div>
                                            </b-alert>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col v-if="eventData.foreman" cols="12" sm="12" md="12" xl="12">
                                        <strong> Main Foreman : {{ eventData.foreman }} </strong>
                                    </b-col>

                                    <b-col v-if="!eventData.foreman && eventData.teamList.length > 0" class="text-danger" cols="12" sm="12" md="12" xl="12">
                                        No Foreman has been assigned
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                        <!-- Field: Team List -->

                        <b-col cols="12">
                            <b-alert variant="secondary" show>
                                <div class="alert-body">
                                    <span class="text-center h4">Staff List</span>
                                </div>
                            </b-alert>
                            <b-table striped hover ref="refUserListTable" class="position-relative" :items="staffList" filter-debounce="250" responsive :small="true" head-variant="dark" :fields="tableColumns" show-empty empty-text="No matching records found">
                                <template #cell(id)="data">
                                    {{ data.value }}
                                </template>

                                <template #cell(role)="data">
                                    {{ data.value }}
                                </template>
                                <template #cell(employeeName)="data">
                                    {{ data.value }}
                                </template>
<!-- 
                                <template #cell(employeeRate)="data">
                                    <span v-if="eventData.rateType == 1">{{
                                        data.item.day_rate ? $func.formatPrice(data.item.day_rate, eventData.currency) : $func.formatPrice(0, eventData.currency)
                                    }}</span>
                                    <span v-if="eventData.rateType == 2">{{
                                        data.item.day_rate_eu ? $func.formatPrice(data.item.day_rate_eu, eventData.currency) : $func.formatPrice(0, eventData.currency)
                                    }}</span>
                                    <span v-if="eventData.rateType == 3">{{
                                        data.item.day_rate_glo ? $func.formatPrice(data.item.day_rate_glo, eventData.currency) : $func.formatPrice(0, eventData.currency)
                                    }}</span>
                                </template>

                                <template #cell(employeeWeekendRate)="data">
                                    <span v-if="eventData.rateType == 1">
                                        {{ data.item.weekend_rate ? $func.formatPrice(data.item.weekend_rate, eventData.currency) : $func.formatPrice(0, eventData.currency) }}</span>
                                    <span v-if="eventData.rateType == 2">
                                        {{ data.item.weekend_rate_eu ? $func.formatPrice(data.item.weekend_rate_eu, eventData.currency) : $func.formatPrice(0, eventData.currency) }}</span>
                                    <span v-if="eventData.rateType == 3">
                                        {{ data.item.weekend_rate_glo ? $func.formatPrice(data.item.weekend_rate_glo, eventData.currency) : $func.formatPrice(0, eventData.currency) }}</span>
                                </template> -->

<!-- 
                                <template #cell(invoiceRate)="data">
                                    <span v-if="data.item.role == 'Foreman'">{{ eventData.foreman_rate ? $func.formatPrice(eventData.foreman_rate, eventData.currency) : $func.formatPrice(0, eventData.currency) }}
                                    </span>
                                    <span v-if="data.item.role == 'Staff'">
                                        {{ eventData.staff_rate ? $func.formatPrice(eventData.staff_rate, eventData.currency) : $func.formatPrice(0, eventData.currency) }}
                                    </span>
                                </template>


                                <template #cell(invoiceWeekendRate)="data">
                                    <span v-if="data.item.role == 'Foreman'">{{ eventData.foreman_w_rate ? $func.formatPrice(eventData.foreman_w_rate, eventData.currency) : $func.formatPrice(0, eventData.currency) }}
                                    </span>
                                    <span v-if="data.item.role == 'Staff'">
                                        {{ eventData.staff_w_rate ? $func.formatPrice(eventData.staff_w_rate, eventData.currency) : $func.formatPrice(0, eventData.currency) }}
                                    </span>
                                </template> -->

                                <template #cell(lodgingRate)="data">
                                    <span v-if="data.item.role == 'Foreman'">{{ eventData.f_lodging ? $func.formatPrice(eventData.f_lodging, eventData.currency) : $func.formatPrice(0, eventData.currency) }}
                                    </span>
                                    <span v-if="data.item.role == 'Staff'">
                                        {{ eventData.lodging ? $func.formatPrice(eventData.lodging, eventData.currency) : $func.formatPrice(0, eventData.currency) }}
                                    </span>
                                    <!-- {{ data.item.lodging ? $func.formatPrice(data.item.lodging, eventData.currency) : $func.formatPrice(0, eventData.currency) }} -->
                                </template>

                                <template #cell()="data">
                                    <span v-for="status in data.value" :key="status.id">
                                        <b-badge v-if="status == 'S'" pill :variant="`light-warning`" class="text-capitalize mr-1"> Sick </b-badge>

                                        <b-badge v-else-if="status == 'H'" pill :variant="`info`" class="text-capitalize mr-1"> Holiday </b-badge>

                                        <b-badge v-else-if="status == 'W'" pill :variant="`danger`" class="text-capitalize mr-1"> AWOL </b-badge>

                                        <b-badge v-else-if="status == 'E'" pill :variant="`light-danger`" class="text-capitalize mr-1"> Conflict </b-badge>

                                        <b-badge v-else pill :variant="`success`" class="text-capitalize mr-1"> Available </b-badge>
                                    </span>

                                    <b-badge v-if="data.value == ''" pill :variant="`success`" class="text-capitalize mr-1"> Available </b-badge>
                                </template>

                                <template #cell(action)="data">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon btn-sm" @click="staffDelete(data)">
                                        <feather-icon icon="XIcon" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>

                        <!-- <b-col cols="12" class="mt-2">
                            <b-alert variant="secondary" show>
                                <div class="alert-body">
                                    <span class="text-center h4">Cost Day List</span>
                                </div>
                            </b-alert>
                            <b-table striped hover ref="refUserListTable" class="position-relative" :items="cost_list" filter-debounce="250" responsive :small="true" head-variant="dark" :fields="costColumns" show-empty empty-text="No matching records found">
                                <template #cell(date)="data">
                                    {{ data.value }}
                                </template>

                                <template #cell(description)="data">
                                    {{ data.value }}
                                </template>
                                <template #cell(currency)="data">
                                    {{ $func.getCurrencyCode(data.value) }}
                                </template>
                                <template #cell(cost)="data">
                                    {{ $func.formatPrice(data.value, eventData.currency) }}
                                </template>

                                <template #cell(action)="data">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon btn-sm" @click="staffDelete(data)">
                                        <feather-icon icon="XIcon" />
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col> -->

                        <!-- <b-col cols="12" class="mt-2">
                            <b-alert variant="secondary" show>
                                <div class="alert-body">
                                    <span class="text-center h4">Cost Total List</span>
                                </div>
                            </b-alert>
                            <b-table striped hover ref="refUserListTable" :fields="costTableTotals" class="position-relative" :items="[{ hotel: hotelTotal, rates: ratesTotal, lodging: lodgingTotal, congestion: congestionTotal, total: totalCosts }]" filter-debounce="250" responsive :small="true"
                                head-variant="dark" show-empty empty-text="No matching records found">
                                <template #cell(hotel)="data">
                                    {{ $func.formatPrice(data.value, eventData.currency) }}
                                </template>
                                <template #cell(rates)="data">
                                    {{ $func.formatPrice(data.value, eventData.currency) }}
                                </template>
                                <template #cell(lodging)="data">
                                    {{ $func.formatPrice(data.value, eventData.currency) }}
                                </template>
                                <template #cell(congestion)="data">
                                    {{ $func.formatPrice(data.value, eventData.currency) }}
                                </template>
                                <template #cell(total)="data">
                                    {{ $func.formatPrice(data.value, eventData.currency) }}
                                </template>
                            </b-table>
                        </b-col> -->

                        <b-col cols="12" class="mt-5">
                            <b-button v-if="eventId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                                Save
                            </b-button>
                            <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                                Save Changes
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-modal id="modal-group-dates" ref="modal-group-dates" cancel-variant="outline-secondary" ok-title="Save" @ok="handleUpdateGroupDates" cancel-title="Close" :no-close-on-backdrop="true" centered title="Select Group Dates" @hide="groupDatesHandleCancel">
                        <b-form ref="groupDatesRules">
                            <b-form-group :state="groupDatesState" invalid-feedback="Date field is required" label="Date*" label-for="date">
                                <flat-pickr id="date" :state="groupDatesState" input-id="date" v-model="groupDates" class="form-control" :config="configPickr" />
                            </b-form-group>
                        </b-form>
                    </b-modal>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import eventStoreModule from "./eventStoreModule";
import ImageCropper from "@core/components/image-cropper/ImageCropper.vue";
import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import moment from "moment";
import { formatDate } from "@/@core/utils/filter";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BRow,
        BCol,
        flatPickr,
        vSelect,
        Cleave,
        ToastificationContent,
        ImageCropper,
        ValidationProvider,
        ValidationObserver,
    },

    directives: {
        Ripple,
        "b-modal": VBModal,
    },

    setup() {
        const EVENT_APP_STORE_MODULE_NAME = "event";

        // Register module
        if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_APP_STORE_MODULE_NAME, eventStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME);
        });
    },

    data: function () {
        var eventData = {
            client_id: null,
            foreman_id: null,
            foreman: null,
            congestionCharge: null,
            date: null,
            employeesRequired: null,
            hotel: null,
            hotelPrice: null,
            invoiceNo: null,
            location: null,
            notes: null,
            overtime: null,
            teamList: [],
            vehicle_id: [],
            vendorReference: null,
            rateType: 1,
            cost_list: [],
            currency: 1,
            foreman_rate: null,
            foreman_w_rate: null,
            staff_rate: null,
            staff_w_rate: null,
            f_overtime_rate: null,
            overtime_rate: null,
            lodging: null,
            f_lodging: null,
            groupReference: null,
        };

        if (this.eventId == undefined) {
            var tableColumns = [
                { key: "id", sortable: false, class: "text-center", thStyle: "width:7%" },
                { key: "role", sortable: false, class: "text-center", thStyle: "width:7%" },
                { key: "employeeName", sortable: false, class: "text-center", thStyle: "width:20%" },
                // { key: "employeeRate", label: "employee Rate", sortable: false, class: "text-center", thStyle: "width:10%" },
                // { key: "invoiceRate", label: "invoice Rate", sortable: false, class: "text-center", thStyle: "width:10%" },
                { key: "lodgingRate", sortable: false, class: "text-center", thStyle: "width:10%" },
                { key: "availability", sortable: false, class: "text-center", thStyle: "width:15%" },
                { key: "action", sortable: false, class: "text-center", thStyle: "width:10%" },
            ];
        } else {
            var tableColumns = [
                { key: "id", sortable: false, class: "text-center", thStyle: "width:7%" },
                { key: "role", sortable: false, class: "text-center", thStyle: "width:7%" },
                { key: "employeeName", sortable: false, class: "text-center", thStyle: "width:20%" },

                // { key: "employeeRate", label: "employee Rate", sortable: false, class: "text-center", thStyle: "width:10%" },
                // { key: "employeeWeekendRate", label: "employee Weekend Rate", sortable: false, class: "text-center", thStyle: "width:16%" },

                // { key: "invoiceRate", label: "invoice Rate", sortable: false, class: "text-center", thStyle: "width:10%" },
                // { key: "invoiceWeekendRate", label: "invoice Weekend Rate", sortable: false, class: "text-center", thStyle: "width:15%" },

                { key: "lodgingRate", sortable: false, class: "text-center", thStyle: "width:10%" },
                { key: "availability", sortable: false, class: "text-center", thStyle: "width:15%" },
                { key: "action", sortable: false, class: "text-center", thStyle: "width:10%" },
            ];
        }

        return {
            formShow: true,
            eventData: eventData,
            eventId: null,
            clientOptions: [],
            groupRefs: [],
            groupRefDates: [],
            groupRefIds: [],

            selectClientData: null,
            teamOptions: [],
            staffOptions: [],
            vehicleOptions: [],
            cost_list: [],
            unavailableForemen: [],

            hotelTotal: 0,
            ratesTotal: 0,
            lodgingTotal: 0,
            totalCosts: 0,
            congestionTotal: 0,
            numberOfStaff: null,
            hotelPrice: null,

            notBooked: false,
            notBookedCount: null,

            rateOptions: [
                {
                    name: "UK",
                    id: 1,
                },
                {
                    name: "EU",
                    id: 2,
                },
                {
                    name: "Global",
                    id: 3,
                },
            ],
            teamSelect: null,
            staffSelect: null,
            hotelSelect: null,
            staffList: [],
            hotelList: [],
            tableColumns: tableColumns,

            tableHotelColumns: [
                { key: "id", sortable: false, class: "text-center", thStyle: "width:15%" },
                { key: "name", sortable: false, class: "text-center", thStyle: "width:30%" },
                { key: "price", sortable: false, class: "text-center", thStyle: "width:20%" },
                { key: "numberOfStaff", label: "Staff Count", sortable: false, class: "text-center", thStyle: "width:20%" },
                { key: "action", sortable: false, class: "text-center", thStyle: "width:15%" },
            ],

            costColumns: [
                { key: "date", sortable: false, class: "text-center" },
                { key: "description", sortable: false, class: "text-center" },
                { key: "currency", sortable: false, class: "text-center" },
                { key: "cost", sortable: false, class: "text-center" },
            ],

            costTableTotals: [
                { key: "hotel", sortable: false, class: "text-center" },
                { key: "rates", sortable: false, class: "text-center" },
                { key: "lodging", sortable: false, class: "text-center" },
                { key: "congestion", sortable: false, class: "text-center" },
                { key: "total", sortable: false, class: "text-center" },
            ],

            currencies: [],

            stafCheckk: false,

            groupDates: null,
            enabledGroupDates: [],
            enabledGroupIds: [],
        };
    },

    created() { },

    mounted() {
        this.getEventDropdownList();
    },

    watch: {
        staffList(val) {
            val.forEach((staff) => {
                let returnData = this.availabilityCheck(staff.id);

                if (this.eventId) {
                    staff.availability = [];
                    returnData.forEach((rData) => {
                        if (staff.availability == undefined) {
                            staff.availability = [rData.availability];
                        } else {
                            staff.availability = [];
                            staff.availability.push(rData.availability);
                        }
                    });
                } else {
                    staff["availability"] = [];
                    returnData.forEach((rData) => {
                        if (staff[rData.date] == undefined) {
                            staff[rData.date] = [rData.availability];
                        } else {
                            staff[rData.date] = [];
                            staff[rData.date].push(rData.availability);
                        }
                    });
                }
            });
        },

        "eventData.client_id": {
            handler: function (val, before) {
                this.clientOptions.forEach((client) => {
                    if (client.id == val) {
                        this.selectClientData = client;
                        this.costUpdate();
                    }
                });
            },
        },

        "eventData.rateType": {
            handler: function (val, before) {
                this.clientInputMethod(this.eventData.client_id);
                this.costUpdate();
            },
        },
    },

    computed: {

        configPickr() {
      return {
        mode: 'multiple',
        locale: { firstDayOfWeek: 1 },
        dateFormat: 'd/m/Y',
        enable: this.enabledGroupDates,
        allowInput: true,
        plugins: [
          ShortcutButtonsPlugin({
            button: [
            {
                label: 'Select All',
              },
              {
                label: 'Clear Selection',
              },
              {
                label: 'Until Job',
              },
              {
                label: 'After Job',
              },
              {
                label: 'Until Today',
              },
              {
                label: 'After Today',
              },
            ],
            label: 'or',
            onClick: (index, fp) => {
                let targetDateParts = this.eventData.date.split('/'); // Split the date string
                let targetDate = new Date(
                    targetDateParts[2], // Year
                    targetDateParts[1] - 1, // Month (subtract 1 since months are 0-indexed)
                    targetDateParts[0] // Day
                );
                let today = new Date();

              let dates = [];
              switch (index) {
                case 0:
                dates = this.enabledGroupDates;

                  break;
                case 1:
                dates = [];


                  break;

                case 2:
                dates = this.enabledGroupDates.filter((dateString) => {
                  // Convert the string date to a JavaScript Date object
      

                  return new Date(dateString) < targetDate;
   
                }); 

                  break;
                case 3:
                dates = this.enabledGroupDates.filter((dateString) => {

                  // Compare the date to the target date
          
                  return new Date(dateString) > targetDate;
                }); 

                  break;
                  case 4:
                dates = this.enabledGroupDates.filter((dateString) => {

                  // Compare the date to the target date
          
                  return new Date(dateString) < today;
                }); 

                  break;
                  case 5:
                dates = this.enabledGroupDates.filter((dateString) => {

                  // Compare the date to the target date
          
                  return new Date(dateString) > today;
                }); 

                  break;
          
              }
              if(dates.length>0){
                let newDates = [];
              dates.forEach(element => {
                let newDate = new Date(element)

                const day = newDate.getUTCDate();
                const month = newDate.getUTCMonth() + 1; // Months are 0-indexed, so add 1
                const year = newDate.getUTCFullYear();
                const dmYDate = `${day}/${month}/${year}`;
                newDates.push(dmYDate);
                
              });
         
              fp.setDate(newDates);

              }
              else{
                fp.setDate([]);

              }
              

            },
          }),
        ],
      };
    },

        countVehicleCal() {
            let vehicleSumSeat = 0;
            if (this.eventData.employeesRequired >= 0 && this.eventData.employeesRequired != null) {
                this.eventData.vehicle_id.forEach((element) => {
                    vehicleSumSeat += this.vehicleOptions.find((ve) => ve.id == element).seat_count;
                });
            }
            return vehicleSumSeat;
        },

        filteredStaffList() {
            let unavailable = [];
            let unavailableLeaders = [];
            this.unavailableForemen = [];

            if (this.eventData.date != undefined) {
                this.eventData.date.split(",").forEach((day) => {
                    unavailable = unavailable.concat(
                        this.staffOptions
                            .map((e) => {
                                return e.availability_date;
                            })
                            .flat(1)
                            .filter((a) => {
                                return a.date == this.dateFormatDMY(day) && (a.availability == "H" || a.availability == "S" || a.availability == "W");
                            })
                            .map((i) => {
                                return i.user_id;
                            })
                    );

                    unavailableLeaders = this.staffOptions
                        .filter((e) => {
                            return e.role == "Foreman";
                        })
                        .map((e) => {
                            return e.availability_date;
                        })
                        .flat(1)
                        .filter((a) => {
                            return a.date == this.dateFormatDMY(day) && a.availability == "E";
                        })
                        .map((i) => {
                            return i.user_id;
                        });
                    this.unavailableForemen = this.unavailableForemen.concat(unavailableLeaders);
                    unavailable = unavailable.concat(unavailableLeaders);
                });
            }
            const uniqueIds = [...new Set(unavailable)];

            return this.staffOptions.filter((staff) => {
                return !uniqueIds.includes(staff.id) && staff.status == "A";
            });
        },

        groupDatesState() {
            return this.groupDates != null && this.groupDates != "" ? true : false;
        },
    },

    methods: {


        selectDropDownCheck(dates, availabilities) {
            let dateSplit = dates.split(/[ ,]+/);
            return dateSplit.some(date => availabilities.some(item => item.date === this.dateFormatDMY(date)));



        },


        formSubmitted() {
            this.$refs.eventRules.validate().then((success) => {
                if (success) {
                    this.formShow = true;
                    store
                        .dispatch("event/eventPoNumberCheck", this.eventData)
                        .then((response) => {
                            //ok
                            this.formShow = false;

                            if (response.data == "CheckPoNumber") {
                                this.$swal({
                                    title: "Invalid PO Number",
                                    text: this.eventData.vendorReference + " is not a valid PO Number. It has been previously registered with another client.",
                                    icon: "error",
                                    showCancelButton: false,
                                    confirmButtonText: "OK",
                                });

                                this.formShow = false;
                            } else if (response.data == "duplicateGroup") {
                                this.$swal({
                                    title: "Invalid Group Reference",
                                    text:
                                        this.eventData.groupReference +
                                        " is not a valid group reference. There is already a job registered with this reference on the selected dates. Please select another the reference or leave it blank.",
                                    icon: "error",
                                    showCancelButton: false,
                                    confirmButtonText: "OK",
                                });

                                this.formShow = false;
                            } else if (response.data == "createJobId") {
                                //save or update continue

                                this.$bvModal
                                    .msgBoxConfirm("Would you like the changes to apply to other days of this event?", {
                                        title: "Please Confirm",
                                        size: "sm",
                                        okVariant: "primary",
                                        okTitle: "Yes",
                                        cancelTitle: "No",
                                        cancelVariant: "outline-secondary",

                                        hideHeaderClose: false,
                                        noCloseOnBackdrop: true,
                                        noCloseOnEsc: true,
                                        centered: true,
                                    })
                                    .then((value) => {
                                        if (value != null) {
                                            this.$refs.eventRules.validate().then((success) => {
                                                // if (this.staffList.length > 0) {
                                                if (success) {
                                                    if (!this.eventId) {
                                                        this.eventSave(value);
                                                    } else {
                                                        if (value) {
                                                            store.dispatch("event/eventGroupPoNumberCheck", this.eventData).then((response) => {
                                                                //ok

                                                                if (response.data == "duplicateGroup") {
                                                                    this.$swal({
                                                                        title: "Invalid Group Reference",
                                                                        text:
                                                                            this.eventData.groupReference +
                                                                            " is not a valid group reference. There is already a job registered with this reference on the selected group dates. Please select another the reference or leave it blank.",
                                                                        icon: "error",
                                                                        showCancelButton: false,
                                                                        confirmButtonText: "OK",
                                                                    });

                                                                    this.formShow = false;
                                                                } else {
                                                                    this.eventUpdate(value);
                                                                }
                                                            });
                                                        } else {
                                                            this.eventUpdate(value);
                                                        }
                                                    }
                                                } else {
                                                    this.$swal({
                                                        title: "Field is required",
                                                        text: "An entry is required or is invalid. Please correct and try again.",
                                                        icon: "error",
                                                        showCancelButton: false,
                                                        confirmButtonText: "OK",
                                                    });

                                                    this.formShow = false;
                                                }
                                            });
                                        }

                                        this.formShow = false;
                                    });
                            } else if (response.data == "noMessageBox") {
                                if (!this.eventId) {
                                    //SAVE
                                    this.eventSave(false);
                                } else {
                                    //update
                                    this.eventUpdate(false);
                                }
                            }
                        })
                        .catch((e) => {
                            console.log(e);

                            this.$swal({
                                title: "Error",
                                text: "Invalid PO Number, please try again with a different number",
                                icon: "error",
                                showCancelButton: false,
                                confirmButtonText: "OK",
                            });

                            this.formShow = false;
                        });
                } else {
                    this.$swal({
                        title: "Field is required",
                        text: "An entry is required or is invalid. Please correct and try again.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }
            });
        },

        // createNewHotelValue(val) {
        //     this.formShow = true;
        //     store
        //         .dispatch("event/addHotel", val)
        //         .then((res) => {
        //             this.$toast({
        //                 component: ToastificationContent,
        //                 props: {
        //                     title: "Successful",
        //                     text: "✔️ Hotel Add Successful",
        //                     icon: "ThumbsUpIcon",
        //                     variant: "success",
        //                 },
        //             });
        //             this.getEventDropdownList();

        //             this.hotelSelect = res.data;

        //             this.formShow = false;
        //         })
        //         .catch((e) => {
        //             let error = e.status.code;
        //             let message = "Process Failed with Error Code:";
        //             let error_message = message.concat(" ", error);

        //             this.$swal({
        //                 title: "Error",
        //                 text: "Process Failed. Please try again or contact support.",
        //                 icon: "error",
        //                 showCancelButton: false,
        //                 confirmButtonText: "OK",
        //             });

        //             this.formShow = false;
        //         });
        // },

        createNewReference(val) {
            this.eventData.groupReference = val.toUpperCase();
            this.groupRefs.unshift(this.eventData.groupReference);
        },

        // selectedHotelValue(val) {
        //     if (val != undefined || val != null) {
        //         this.hotelPrice = val.price;
        //     }
        // },
        availabilityCheck(id) {
            let dateSplit = this.eventData.date.split(/[ ,]+/);

            let availability = [];

            this.staffOptions.forEach((element) => {
                if (element.id == id) {
                    element.availability_date.forEach((e) => {
                        if (e.event_id != this.eventId) {
                            const dStart = this.localDateFormat(e.date);
                            dateSplit.forEach((date) => {
                                if (dStart == date) {
                                    availability.push({
                                        availability: e.availability,
                                        date: date,
                                    });
                                }
                            });
                        }
                    });
                }
            });

            return availability;
        },

        eventSave(val) {
            if (val) {
                this.enabledGroupDates = [];
                this.enabledGroupDates = this.groupRefDates[this.eventData.groupReference].map((a) => {
                    return this.dateFormat(a.date);
                });

                this.groupDates = this.groupRefDates[this.eventData.groupReference].map((a) => {
                    return this.dateFormat(a.date);
                });

                this.eventData.group_ids = this.groupRefIds[this.eventData.groupReference].map((a) => {
                    return a.id;
                });


                this.$bvModal.show("modal-group-dates");

                const modalPromise = new Promise((resolve) => {
                    this.$refs["modal-group-dates"].$once("ok", () => {
                        resolve();
                    });
                });
                modalPromise.then(() => {
                    let updateDates = this.groupDates.split(",").map((e) => {
                        return this.dateFormatYMD(e);
                    });

                    let foremanCheck = this.unavailableForemanList(updateDates, this.eventData.teamList, this.eventData.group_ids);

                    if (foremanCheck.length > 0) {
                        let people = foremanCheck.map((e) => {
                            return e.employeeName;
                        });
                        let errorText = "Selected Foreman [" + people + "] is not available in one of the selected dates. Please change the foreman and try again.";
                        this.$swal({
                            title: "Availability Error",
                            text: errorText,
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });
                        this.getEventDropdownList();

                        this.formShow = false;
                    } else {
                        this.eventData.updateDates = this.groupDates.split(",").map((e) => {
                            return this.dateFormatYMD(e);
                        });

                        this.eventData.cost_list = this.cost_list;
                        this.eventData.msgBox = val;
                        this.formShow = true;
                        store
                            .dispatch("event/eventSave", this.eventData)
                            .then((response) => {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Successful",
                                        text: "✔️ Event Add Successful",
                                        icon: "ThumbsUpIcon",
                                        variant: "success",
                                    },
                                });
                                this.$router.push({ name: "event-list" });
                                this.formShow = false;
                            })
                            .catch((e) => {
                                this.formShow = false;

                                this.$swal({
                                    title: "Error",
                                    text: "Process Failed. Please try again or contact support.",
                                    icon: "error",
                                    showCancelButton: false,
                                    confirmButtonText: "OK",
                                });
                            });
                    }
                });
            } else {
                this.eventData.cost_list = this.cost_list;
                this.eventData.msgBox = false;
                this.formShow = true;
                store
                    .dispatch("event/eventSave", this.eventData)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Successful",
                                text: "✔️ Event Add Successful",
                                icon: "ThumbsUpIcon",
                                variant: "success",
                            },
                        });
                        this.$router.push({ name: "event-list" });
                        this.formShow = false;
                    })
                    .catch((e) => {
                        this.formShow = false;

                        this.$swal({
                            title: "Error",
                            text: "Process Failed. Please try again or contact support.",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });
                    });
            }
        },

        eventUpdate(val) {
            if (val) {
                this.enabledGroupDates = [];

                this.enabledGroupDates = this.eventData.group_dates
                    .map((e) => {
                        if (this.dateFormat(e.date) != this.eventData.date) {


                            return e.date;
                        }
                    })
                    .flat(1);

                this.groupDates = this.eventData.group_dates
                    .map((e) => {
                        return this.dateFormat(e.date);
                    })
                    .flat(1)
                    .join(", ");

                this.$bvModal.show("modal-group-dates");

                const modalPromise = new Promise((resolve) => {
                    this.$refs["modal-group-dates"].$once("ok", () => {

                        resolve();
                    });
                });
                modalPromise.then(() => {
                    let updateDates = this.groupDates.split(",").map((e) => {
                        return this.dateFormatYMD(e);
                    });

                    let foremanCheck = this.unavailableForemanList(updateDates, this.eventData.teamList, this.eventData.group_ids);
                    if (foremanCheck.length > 0) {
                        let people = foremanCheck.map((e) => {
                            return e.employeeName;
                        });
                        let errorText = "Selected Foreman [" + people + "] is not available in one of the selected dates. Please change the foreman and try again.";
                        this.$swal({
                            title: "Availability Error",
                            text: errorText,
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });
                        // this.getEventDropdownList();

                        this.formShow = false;
                    } else {
                        this.eventData.updateDates = this.groupDates.split(",").map((e) => {
                            return this.dateFormatYMD(e);
                        });
                        this.eventData.cost_list = this.cost_list;
                        this.formShow = true;
                        this.eventData.msgBox = val;
                        store
                            .dispatch("event/eventUpdate", this.eventData)
                            .then((res) => {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Successful",
                                        text: "✔️ Event Update Successful",
                                        icon: "ThumbsUpIcon",
                                        variant: "success",
                                    },
                                });
                                this.$router.push({ name: "event-view", params: { id: this.eventId } });
                                this.formShow = false;
                            })
                            .catch((e) => {
                                this.$swal({
                                    title: "Error",
                                    text: "Process Failed. Please try again or contact support.",
                                    icon: "error",
                                    showCancelButton: false,
                                    confirmButtonText: "OK",
                                });

                                this.formShow = false;
                            });
                    }
                });
            } else {
                this.eventData.cost_list = this.cost_list;
                this.formShow = true;
                this.eventData.msgBox = val;
                store
                    .dispatch("event/eventUpdate", this.eventData)
                    .then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Successful",
                                text: "✔️ Event Update Successful",
                                icon: "ThumbsUpIcon",
                                variant: "success",
                            },
                        });
                        this.$router.push({ name: "event-view", params: { id: this.eventId } });
                        this.formShow = false;
                    })
                    .catch((e) => {
                        this.$swal({
                            title: "Error",
                            text: "Process Failed. Please try again or contact support.",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });

                        this.formShow = false;
                    });
            }
        },

        getEventShow() {
            this.formShow = true;
            this.hotelList = [];
            this.cost_list = [];
            this.hotelTotal = 0;
            this.congestionTotal = 0;
            this.ratesTotal = 0;
            this.lodgingTotal = 0;
            this.totalCosts = 0;

            this.eventId = router.currentRoute.params.id;

            if (this.eventId) {
                store
                    .dispatch("event/eventShow", { id: this.eventId })
                    .then((response) => {
                        this.eventData = response.data;

                        this.clientOptions = this.clientOptions.filter((e) => {
                            return e.status == "A" || e.id == this.eventData.client_id;
                        });
                        this.vehicleOptions = this.vehicleOptions.filter((e) => {
                            return e.status == "A" || this.eventData.vehicle_id.includes(e.id);
                        });
                        response.data.hotel.forEach((element) => {
                            this.hotelList.push(element);
                        });

                        response.data.cost_list.forEach((element) => {
                            this.cost_list.push(element);

                            if (element.description == "HOTEL") {
                                this.hotelTotal += Number(element.cost);
                            } else if (element.description == "CONGESTION CHARGE") {
                                this.congestionTotal = Number(element.cost);
                            } else if (element.description == "RATES") {
                                this.ratesTotal = Number(element.cost);
                            } else if (element.description == "LODGING") {
                                this.lodgingTotal = Number(element.cost);
                            }
                        });

                        this.totalCosts += Number(this.hotelTotal) + Number(this.congestionTotal) + Number(this.ratesTotal) + Number(this.lodgingTotal);
                        this.staffList = response.data.teamList;
                        this.formShow = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.vehicleOptions = this.vehicleOptions.filter((e) => {
                    return e.status == "A";
                });
                this.clientOptions = this.clientOptions.filter((e) => {
                    return e.status == "A";
                });
                this.eventData.currency = this.$func.getDefaultCurrency();
                this.formShow = false;
            }
        },

        dateChange() {
            if (!this.eventId) {
                this.tableColumns = [];
                this.tableColumns.push(
                    { key: "id", sortable: false, class: "text-center", thStyle: "width:7%" },
                    { key: "role", sortable: false, class: "text-center", thStyle: "width:7%" },
                    { key: "employeeName", sortable: false, class: "text-center", thStyle: "width:20%" },

                    { key: "lodgingRate", sortable: false, class: "text-center", thStyle: "width:10%" }
                );

                let dateSplit = this.eventData.date.split(/[ ,]+/);

                dateSplit.forEach((element) => {
                    this.tableColumns.push({ key: element, sortable: false, class: "text-center", thStyle: "width:20px" });
                });

                this.tableColumns.push({ key: "action", sortable: false, class: "text-center", thStyle: "width:10%" });

                this.teamSelect = null;
                this.staffSelect = null;
                this.hotelSelect = null;
                this.eventData.teamList = [];
                this.eventData.cost_list = [];
                this.staffList = [];
                this.cost_list = [];
                this.hotelTotal = 0;
                this.ratesTotal = 0;
                this.lodgingTotal = 0;
                this.totalCosts = 0;
                this.congestionTotal = 0;

                // this.tableColumns
            }
        },

        getEventDropdownList() {
            store
                .dispatch("event/getEventDropdownList")
                .then((res) => {
                    this.teamOptions = res.data.teams;
                    this.staffOptions = res.data.staff;
                    this.currencies = res.data.currencies;
                    this.groupRefs = res.data.groupRefs;
                    this.groupRefDates = res.data.groupDates;
                    this.groupRefIds = res.data.groupRefIds;
                    this.clientOptions = res.data.clients;
                    this.vehicleOptions = res.data.vehicles;

                    this.getEventShow();
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        staffAdd() {
            if (this.staffSelect != undefined && this.staffSelect != null && this.staffSelect != "") {
                this.staffList.push({
                    id: this.staffSelect.id,
                    role: this.staffSelect.role,
                    employeeName: this.staffSelect.name + " " + this.staffSelect.surname,
                    day_rate: this.staffSelect.day_rate,
                    day_rate_eu: this.staffSelect.day_rate_eu,
                    weekend_rate_eu: this.staffSelect.weekend_rate_eu,
                    day_rate_glo: this.staffSelect.day_rate_glo,
                    weekend_rate: this.staffSelect.weekend_rate,
                    weekend_rate_glo: this.staffSelect.weekend_rate_glo,

                    //   availability: availability,
                    lodging: this.staffSelect.lodging,
                });

                this.staffSelect = null;
                this.eventData.teamList = this.staffList;
                if (this.eventData.foreman_id < 1 && this.staffList.find((item) => item.role == "Foreman")) {
                    var foreman = this.staffList.find((item) => item.role == "Foreman");
                    this.eventData.foreman_id = foreman.id;
                    this.eventData.foreman = foreman.employeeName;
                }

                this.costUpdate();
            }
        },

        staffDelete(data) {
            // if (data.item.role == 'Foreman') {
            //   this.staffList = [];
            // } else {

            this.staffList.splice(data.index, 1);
            this.eventData.teamList = this.staffList;

            if (data.item.id == this.eventData.foreman_id) {
                this.eventData.foreman_id = null;
                if (this.staffList.some((item) => item.role == "Foreman")) {
                    var foreman = this.staffList.find((item) => item.role == "Foreman");
                    this.eventData.foreman_id = foreman.id ?? null;
                    this.eventData.foreman = foreman.employeeName ?? null;
                } else {
                    this.eventData.foreman_id = null;
                    this.eventData.foreman = null;
                }
            }

            this.costUpdate();
        },

        hotelAdd() {
            this.hotelList.push({
                // id: this.hotelSelect,
                name: this.hotelSelect,
                price: this.hotelPrice,
                numberOfStaff: this.numberOfStaff,
            });

            this.hotelSelect = null;
            this.hotelPrice = null;
            this.numberOfStaff = null;
            this.eventData.hotel = this.hotelList;
            this.costUpdate();
        },

        hotelAddNotBooked() {
            this.hotelList.push({
                // id: this.hotelSelect,
                name: 'Hotel Not Booked',
                price: 0,
                numberOfStaff: this.notBookedCount,
            });

   
            this.notBookedCount = null,
            this.eventData.hotel = this.hotelList;
            this.costUpdate();
        },


        hotelDelete(data) {
            this.hotelList.splice(data.index, 1);
            this.eventData.hotel = this.hotelList;
            this.costUpdate();
        },

        costUpdate() {
            this.hotelTotal = 0;
            this.congestionTotal = 0;
            this.ratesTotal = 0;
            this.totalCosts = 0;
            this.lodgingTotal = 0;
            let dateSplit = this.eventData.date.split(/[ ,]+/);
            var totalOvertime = 0;

            // this.cost_list = this.cost_list.filter((item) => item.description != "HOTEL");
            this.cost_list = this.cost_list.filter((item) => item.description != "CONGESTION CHARGE");
            this.cost_list = this.cost_list.filter((item) => item.description != "LODGING");
            // let mostDate = '01/01/2020';

            dateSplit.forEach((date, index) => {
                var d = moment(date, "DD/MM/YYYY");
                var rates = 0;
                var lodging = 0;

                totalOvertime = 0;

                this.staffList.forEach((staff) => {

                    if (this.lodgingCheck(date, staff.id) > 0) {
                        if (staff.role == "Foreman") {
                            lodging += Number(this.eventData.f_lodging);
                        } else {
                            lodging += Number(this.eventData.lodging);
                        }
                    }

                    if (staff.overtime_hours > 0) {
                        if (staff.role == "Foreman") {
                            totalOvertime += staff.overtime_hours * this.eventData.f_overtime_rate;
                        } else {
                            totalOvertime += staff.overtime_hours * this.eventData.overtime_rate;
                        }
                    }
                });

                // this.hotelList.forEach((hotel) => {
                //     this.hotelTotal += Number(hotel.price * hotel.numberOfStaff);
                //     this.cost_list.push({ description: "HOTEL", currency: this.eventData.currency, cost: hotel.price * hotel.numberOfStaff, hotelName: hotel.name, date: date });
                // });
                this.cost_list.push({ description: "CONGESTION CHARGE", currency: this.eventData.currency, cost: Number(this.eventData.congestionCharge), date: date });

                if (lodging > 0) {
                    this.cost_list.push({ description: "LODGING", currency: this.eventData.currency, cost: lodging, date: date });
                    this.lodgingTotal += Number(lodging);
                }


                this.congestionTotal += Number(this.eventData.congestionCharge);
                this.totalCosts =  Number(this.congestionTotal) + Number(this.lodgingTotal);
            });

            // this.eventData.hotelPrice = this.hotelTotal;
        },

        clientInputMethod(val) {
            this.clientOptions.forEach((client) => {
                if (client.id == val) {
                    // if (this.eventData.rateType == 1) {
                    //     this.eventData.foreman_rate = client.f_day_rate;
                    //     this.eventData.foreman_w_rate = client.f_weekend_rate;

                    //     this.eventData.staff_rate = client.day_rate;
                    //     this.eventData.staff_w_rate = client.weekend_rate;
                    // } else if (this.eventData.rateType == 2) {
                    //     this.eventData.foreman_rate = client.f_day_rate_eu;
                    //     this.eventData.foreman_w_rate = client.f_weekend_rate_eu;

                    //     this.eventData.staff_rate = client.day_rate_eu;
                    //     this.eventData.staff_w_rate = client.weekend_rate_eu;
                    // } else if (this.eventData.rateType == 3) {
                    //     this.eventData.foreman_rate = client.f_day_rate_glo;
                    //     this.eventData.foreman_w_rate = client.f_weekend_rate_glo;

                    //     this.eventData.staff_rate = client.day_rate_glo;
                    //     this.eventData.staff_w_rate = client.weekend_rate_glo;
                    // }

                    // this.eventData.f_overtime_rate = client.f_overtime;
                    // this.eventData.overtime_rate = client.overtime;

                    this.eventData.f_lodging = client.f_lodging;
                    this.eventData.lodging = client.lodging;
                }
            });
        },

        lodgingCheck(date, staffId) {
            var returnCount = 0;
            let dateSplit = this.eventData.date.split(/[ ,]+/);

            dateSplit.forEach((da, index) => {
                var d = moment(da, "DD/MM/YYYY");
                if (this.localDateFormat(date) == this.localDateFormatOneDayBefore(d)) {
                    returnCount++;
                }
            });

            var stafData = this.staffOptions.find((staff) => staff.id == staffId);

            stafData.availability_date.forEach((stafJob) => {
                if (this.localDateFormat(date) == this.localDateFormatOneDayBefore(stafJob.date)) {
                    returnCount++;
                }
            });

            return returnCount;
            // this.staffOptions.forEach((staff) => {

            //   if (staffId == staff.id) {
            //     let x = 0;
            //     staff.availability_date.forEach((stafJob) => {
            //       if (this.localDateFormat(mostDate) == this.localDateFormatOneDayBefore(stafJob.date)) {
            //         console.log(this.localDateFormat(mostDate));
            //         console.log(this.localDateFormatOneDayBefore(stafJob.date));
            //         x++;
            //         lodging += staffTable.lodging;
            //       }
            //     });
            //     if (x > 0) {
            //       costJson = { description: 'LODGING', currency: this.eventData.currency, cost: lodging, date: this.localDateFormat(mostDate) };
            //       //this.cost_list.push();
            //     }
            //   }
            // });

            // if (staffTable.overtime_hours > 0) {
            //   if (staffTable.role == 'Foreman') {
            //     totalOvertime += staffTable.overtime_hours * this.eventData.f_overtime_rate;
            //   } else {
            //     totalOvertime += staffTable.overtime_hours * this.eventData.overtime_rate;
            //   }
            // }
        },

        dateFormat(val) {
            moment.locale("en-US");

            return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
        },

        dateFormatDMY(val) {
            moment.locale("en-US");
            return moment(val, "DD/MM/YYYY").format("DD-MM-YYYY");
        },

        dateFormatYMDtoDMY(val) {
            moment.locale("en-US");
            return moment(val, "YYYY-MM-DD").format("DD-MM-YYYY");
        },

        dateFormatYMD(val) {
            moment.locale("en-US");
            return moment(val, "DD/MM/YYYY").format("YYYY-MM-DD");
        },

        localDateFormat(val) {
            moment.locale("en-US");

            return moment(val, "DD-MM-YYYY").format("DD/MM/YYYY");
        },

        localDateFormatOneDayBefore(val) {
            moment.locale("en-US");

            return moment(val, "DD-MM-YYYY")
                .add(-1)
                .format("DD/MM/YYYY");
        },

        teamSelectDropdown() {
            // this.staffList = [];
            let warningText = "Foreman of this team [" + this.teamSelect.foreman.name + " " + this.teamSelect.foreman.surname + "] is unavailable on the selected dates. Please select another foreman. <br> <strong> Unavailable dates:</strong> <br>";
            if (this.unavailableForemen.includes(Number(this.teamSelect.foreman_id))) {
                const dates = this.staffOptions.filter((e)=> e.id == Number(this.teamSelect.foreman_id))[0]
                const events = [];
                this.eventData.date.split(",").forEach((day) => {
                    dates.availability_date.filter((a) => {
                                if(a.date == this.dateFormatDMY(day) && (a.availability == "H" || a.availability == "S" || a.availability == "W"|| a.availability == "E")){
                                    events.push(a)
                                }
                            })

                });
                store
                        .dispatch("event/getEventLocations", {'data':events})
                        .then((response) => {
                            let eventList = '<ul>';
                            response.data.locations.forEach((element) => {
                                eventList += '<li>' + element + '</li>';
                            });
                            eventList += '</ul>';
                            warningText += eventList;
                            this.$swal({
                            title: "Foreman Unavailable",
                            html: warningText,
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                            dangerMode: true, // Set dangerMode to true to interpret HTML content
                        });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
              }



            if (this.eventData.foreman_id < 1 && !this.unavailableForemen.includes(Number(this.teamSelect.foreman_id))) {
                this.eventData.foreman_id = this.teamSelect.foreman_id;
                this.eventData.foreman = this.teamSelect.foreman.name + " " + this.teamSelect.foreman.surname;
            }

            this.teamSelect.staff.forEach((staff) => {
                var existCheck = this.staffList.find((item) => item.id == staff.id);

                if (existCheck == undefined && !this.unavailableForemen.includes(staff.id)) {
                    this.staffList.push({
                        id: staff.id,
                        role: staff.role,
                        employeeName: staff.name + " " + staff.surname,
                        day_rate: staff.day_rate,
                        day_rate_eu: staff.day_rate_eu,
                        weekend_rate_eu: staff.weekend_rate_eu,
                        day_rate_glo: staff.day_rate_glo,
                        weekend_rate: staff.weekend_rate,
                        weekend_rate_glo: staff.weekend_rate_glo,
                        lodging: staff.lodging,
                    });
                }
            });

            this.teamSelect = null;
            this.staffSelect = null;
            this.stafCheckk = false;
            this.eventData.teamList = this.staffList;
            this.costUpdate();
        },

        staffSelectDropdown() {
            var counterStaff = 0;
            this.eventData.teamList.forEach((team) => {
                if (team.id == this.staffSelect.id) {
                    counterStaff++;
                }
            });

            if (counterStaff > 0) {
                this.stafCheckk = true;
            } else {
                this.stafCheckk = false;
            }

            counterStaff = 0;
        },

        handleUpdateGroupDates(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.modalUpdateGroupDates();
        },

        modalUpdateGroupDates(val) {
            if (!this.checkFormValidityGroupDates()) {
                return;
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-group-dates");
            });
        },

        groupDatesHandleCancel() {
            this.getEventDropdownList();
        },

        checkFormValidityGroupDates() {
            const groupDatesValid = this.$refs.groupDatesRules.checkValidity();

            return groupDatesValid;
        },

        unavailableForemanList(dates, tempList, eventIds) {
            let unavailableLeaders = [];
            let unavailableForemen = [];
            let usedIds = eventIds.map((e) => {
                return e.id;
            });
            let selectedIds = tempList.map((e) => {
                return e.id;
            });

            dates.forEach((day) => {
                unavailableLeaders = this.staffOptions
                    .filter((e) => {
                        return e.role == "Foreman";
                    })
                    .map((e) => {
                        return e.availability_date;
                    })
                    .flat(1)
                    .filter((a) => {
                        return a.date == this.dateFormatYMDtoDMY(day) && a.availability == "E" && (a.event_id == null || !usedIds.includes(a.event_id));
                    })
                    .map((i) => {
                        return i.user_id;
                    });

                unavailableForemen = unavailableForemen.concat(unavailableLeaders);
            });
            const uniqueIds = [...new Set(unavailableForemen)];

            const intersect = uniqueIds.filter((value) => selectedIds.includes(value));

            return this.staffList.filter((staff) => intersect.includes(staff.id));
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.disabled {
    pointer-events: none;
    color: #bfcbd9;
    cursor: not-allowed;
    background-image: none;
    background-color: #eef1f6;
    border-color: #d1dbe5;
}

</style>
<style>


.side-by-side-container {
  display: flex;
  align-items: center; /* Optional: To vertically center the content */
}

.side-by-side-container label {
  margin-right: 10px; /* Optional: Add some spacing between the labels and input elements */
}


.shortcut-buttons-flatpickr-button{
    width:28%;
  border-radius: 5px;
  background: white;
  border: 1px solid #d8d6de;
  font-size: small;
}
</style>

